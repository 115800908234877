import Image from "next/image";
import React from "react";
import styled, { keyframes } from "styled-components";
import Fill from "/public/images/NewAllGames/elements/bg-fill.svg";
import Fill2 from "/public/images/NewAllGames/elements/bg-fill-2.svg";
import Link from "next/link";

// eslint-disable-next-line react/display-name
export const SlotsAnimatedBg = React.memo(() => {
  return (
    <Overlay>
      <div className="bg-2" style={{ position: "absolute", inset: 0, borderRadius: 12 }} />
      <div style={{ position: "absolute", right: 32, top: 32, zIndex: 0 }}>
        <Fill2 />
      </div>
      <div className="main-image-2">
        <Image
          className="img-1"
          src={"/images/NewAllGames/elements/char-1.png"}
          width={230}
          height={230}
          alt=""
          style={{ zIndex: 2, position: "relative" }}
        />
        <Image
          className="img-2"
          src={"/images/NewAllGames/elements/char-3.png"}
          width={230}
          height={230}
          alt=""
        />
        <Image
          className="img-3"
          src={"/images/NewAllGames/elements/char-2.png"}
          width={230}
          height={230}
          alt=""
          style={{ zIndex: 2, position: "relative" }}
        />
        {/* <MainW/> */}
      </div>
      <div className="animated">
        {["s-1", "s-2", "s-3", "s-4", 2, 3, 4, 5, 6, 7, 8, 9].map((item, key) => {
          return (
            <div className={`item item-${item}`} key={`${item}-${key}`}>
              <Image src={`/images/NewAllGames/elements/${item}.png`} alt="" fill />
            </div>
          );
        })}
      </div>
    </Overlay>
  );
});

// eslint-disable-next-line react/display-name
export const AllGamesAnimated = React.memo(() => {
  return (
    <Overlay>
      <div style={{ position: "absolute", right: 0, top: 0 }}>
        <Fill />
      </div>
      <div className="main-image">
        <Image src={"/images/NewAllGames/elements/1.png"} width={230} height={230} alt="" />
      </div>
      <div className="animated">
        {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item, key) => {
          return (
            <div className={`item item-${item}`} key={`${item}-${key}`}>
              <Image
                src={`/images/NewAllGames/elements/${item}.png`}
                alt=""
                fill
                sizes="(max-width: 768px) 80px, (max-width: 1200px) 100px, 120px"
              />
            </div>
          );
        })}
      </div>
    </Overlay>
  );
});

const Overlay = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
  border-radius: 12px;
`;

export const NavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background: #181e30;
  

  .items {
    padding: 0.5rem;
    border-radius: 12px;
    background: #181e30;
    display: flex;
    gap: 8px;
  }

  @media (max-width: 940px) {
    flex-direction: column;
    width: 100%;
    background: transparent;
    gap: 16px;

    .links {
      overflow-x: scroll;
      scroll-snap-align: 1;
    }

    .sorts {
      background: transparent;
      display: flex;
      flex-direction: column;
      padding: 0;
      width: 100%;
    }
  }
`;

export const NavigationItem = styled(Link)`
  display: flex;
  border-radius: 0.5rem;
  /* background: var(--Dark-Space-Blue, #181e30); */
  background: transparent;
  padding: 8px 16px;
  font-family: var(--font-family-golos);
  color: var(--clouds-100, #abbadb);
  font-size: 14px;
  font-weight: 500;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 20px;
    height: 20px;
  }

  &[data-active="true"] {
    background: var(--pure-sky-5, rgba(0, 178, 255, 0.05));
    color: #00b2ff;
  }

  &:hover,
  .active {
    background: var(--pure-sky-5, rgba(0, 178, 255, 0.05));
    color: #00b2ff;
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1.09);
  }
`;

const fall = keyframes`
    0% {
        transform: translate(0px,-100px);
        opacity: 0;
    }

    2% {
        opacity: 1;
    }

    38% {
        opacity: 1;
    }

    40% {
        transform: translate(150px,500px);
        opacity: 0;
    }
    41% {
        opacity: 0;
        transform: translate(0px,-350px);
    }
    42% {
        opacity: 1;
    }
    98% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translate(0px,500px);
    }
`;

const pulse = keyframes`
 0% { 
  transform: scale(1) ;

  }

 50% {   
  transform: scale(1.05);

  }

 100% {   
  transform: scale(1);

  }
`;

export const SearchSection = styled.div`
  margin-top: 48px;
  padding: 24px;
  background: rgb(28, 36, 61);
  border-radius: 0.75rem;

  position: relative;
  /* overflow: hidden; */
  min-height: 200px;
  display: flex;
  flex-direction: column;
  &.bg-1 {
    background: linear-gradient(
      135deg,
      rgba(28, 36, 61, 1) 14%,
      rgba(23, 28, 45, 1) 54%,
      rgba(19, 22, 34, 1) 83%,
      rgba(255, 132, 56, 0) 140%
    );
  }

  &.bg-2,
  .bg-2 {
    background: linear-gradient(
      155deg,
      rgba(255, 255, 255, 0.02) 0%,
      rgba(255, 255, 255, 0) 15%,
      rgba(31, 75, 229, 0.5186449579831933) 54%,
      rgba(0, 115, 250, 0.938813025210084) 83%,
      rgba(2, 88, 188, 1) 100%
    );
  }

  .animated {
    position: absolute;
    inset: -100px 0;
    border-radius: 0.75rem;
    overflow: hidden;
    /* &:hover {
      .item {
        animation-play-state: running;
      }
    } */

    .item {
      will-change: transform, opacity;
      position: absolute;
      animation: 4.8s ${fall} ease-in infinite;
      opacity: 0;
    }
    .item-1,
    .item-s-1 {
      width: 80px;
      height: 80px;
      right: 35%;
      animation-delay: 1s;
    }

    .item-2,
    .item-s-2 {
      width: 50px;
      height: 50px;
      right: 25%;
      animation-delay: 0.8s;
    }

    .item-3,
    .item-s-3 {
      width: 60px;
      height: 60px;
      right: 15%;
      animation-delay: 1.5s;
    }

    .item-4,
    .item-s-4 {
      width: 120px;
      height: 120px;
      right: 5%;
      animation-delay: 2s;
    }

    .item-5 {
      width: 80px;
      height: 80px;
      right: 8%;
      animation-delay: 1s;
    }

    .item-6 {
      width: 50px;
      height: 50px;
      right: 18%;
      animation-delay: 0.5s;
    }

    .item-7 {
      width: 60px;
      height: 60px;
      right: 25%;
      animation-delay: 1.5s;
    }

    .item-8 {
      width: 120px;
      height: 120px;
      right: 34%;
      animation-delay: 0.6s;
      z-index: 10;
    }
    .item-9 {
      width: 120px;
      height: 120px;
      right: 45%;
      animation-delay: 2s;
    }
    .item-10 {
      width: 120px;
      height: 120px;
      right: 30%;
      animation-delay: 1.4s;
    }
    .item-11 {
      width: 120px;
      height: 120px;
      right: 47%;
      animation-delay: 1.6s;
    }
  }

  .main-image {
    position: relative;
    animation-name: ${pulse};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    z-index: 2;
    position: absolute;
    right: 25%;
    top: -10%;
    img {
      z-index: 3;
    }
    @media (max-width: 940px) {
      top: initial;
      bottom: 0;
      left: calc(50% - 115px);
      right: initial;
    }
    &::after {
      position: absolute;
      inset: -100px;
      content: "";
      background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 15%,
        rgba(245, 188, 0, 0.21) 54%,
        rgba(255, 86, 24, 0.21) 83%,
        rgba(255, 132, 56, 0) 100%,
        rgba(254, 138, 66, 1) 140%
      );
      border-radius: 100%;
      filter: blur(150px);
      /* backdrop-filter: blur(20px); */
    }
  }

  .main-image-2 {
    position: relative;
    /* animation-name: ${pulse};
    animation-duration: 2s;
    animation-iteration-count: infinite; */
    z-index: 0;
    position: absolute;
    right: 2rem;
    top: 1rem;

    .img-1 {
      position: absolute;
      right: -0px;
      top: -10%;
      /* z-index: 3; */
    }

    .img-2 {
      position: absolute;
      right: 100px;
      z-index: 2;
    }

    .img-3 {
      position: absolute;
      right: -40px;
      top: -10%;
      z-index: 3;
    }

    @media (max-width: 940px) {
      top: 300px;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &::after {
      position: absolute;
      inset: -100px;
      content: "";
      /* background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.2) 15%,
        rgba(245, 188, 0, 0.21) 54%,
        rgba(255, 86, 24, 0.21) 83%,
        rgba(255, 132, 56, 0) 100%,
        rgba(254, 138, 66, 1) 140%
      ); */
      border-radius: 100%;
      filter: blur(150px);
      /* backdrop-filter: blur(20px); */
    }
  }

  @media (max-width: 940px) {
    padding-bottom: 240px;

    & .animated {
      inset: 0 0 0 -450px;
    }
  }
`;

export const OriginGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;

  
  @media (max-width: 940px) {
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  }
`;

export const SearchBlock = styled.div`
  position: relative;
  height: 100%;
  /* z-index: 4; */
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 450px;
  gap: 24px;
  z-index: 10;
  backdrop-filter: blur(30px);
  margin: -24px;
  padding: 24px;
  width: calc(100% + 48px);
  border-radius: 0.75rem;

  @media (min-width: 1920px) {
    max-width: 600px;
  }

  @media (max-width: 940px) {
    max-width: calc(100% + 48px);
  }

  .title {
    font-family: var(--font-family-exo);
    font-size: 30px;
    font-style: italic;
    font-weight: 800;
    margin: 0;
    color: var(--daylight-daylight-main, #d5e0f5);
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .icon {
    color: #00b2ff;
  }
  .search-input {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .chakra-portal {
      position: relative;
      max-width: 100%;
    }

    .chakra-popover__popper {
      min-width: 100% !important;
    }
  }

  .label {
    font-family: var(--font-family-golos);
    font-size: 14px;
    font-style: normal;
    color: var(--clouds-100, #abbadb);
  }
`;

export const ComponentTitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  font-family: var(--font-family-golos);
  font-weight: 600;
  color: var(--daylight-color);
`;

export const ComponentTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  margin: 0;
  svg {
    width: 24px;
    height: 24px;
    color: var(--pure-sky);
  }
`;

export const ComponentTitleLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  & path {
    fill: var(--pure-sky);
  }

  svg {
    transform: rotate(90deg) translateY(0) scale(1);
    transition: transform 0.3s;
  }

  &:hover {
    svg {
      transform: rotate(270deg) translateY(3px) scale(1.5);
    }
  }
`;

export const SlotGrid = styled.div`
  margin-top: 1rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  position: relative;
`;
